.aboutupperright {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row-reverse;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.aboutupper {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.headingtop{
  font-size: 50px;
  text-align: center;
  font-weight: 800;
  color: black;
}
.aboutustop{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
}
.gopaljiheading {
  width: 93%;
  background-image: url(./../images/header-bg.jpg);
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: 110% 150%;
  height: 30vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* background-size: cover; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutupper img {
  width: 500px;
  height: 400px;
  border-radius: 35% 65% 36% 64% / 30% 16% 84% 70%;
  padding: 0px 10px 0px 10px;
  transition: transform 3s ease-in-out;
}

.aboutupper img:hover {
  transform: scale(1.1);
}

.aboutupperright img {
  width: 500px;
  height: 400px;
  border-radius: 35% 65% 76% 24% / 15% 51% 49% 85%;
  padding: 0px 10px 0px 10px;
  transition: transform 3s ease-in-out;
}

.aboutupperright img:hover {
  transform: scale(1.1);
}

.aboutinner {
  padding: 10px 10px 5px 16px;
}

.aboutinner p {
  font-size: 16px;
  font-weight: 600;
  width: 50rem;
  text-align: justify;
  line-height: 24px;
}
.aboutinner h2 {
  font-size: 30px;
  color: #ffd301;
  font-weight: 700;
  margin: 0px 0px 10px 0px;
  width: 50rem;
  /* padding: 5px; */
}

.parap {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: justify;
  line-height: 24px;
  padding: 5px 50px 5px 50px;
}

.otherhistory img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 20px 20px 40px 30px;
  margin-bottom: 2rem;
}
.otherhistory p {
  width: 97%;
  /* text-align: center; */
  font-size: 17px;
  padding-left: 80px;
  text-align: justify;
}

.otherhistory ul{
  padding-left: 80px;
}
.otherhistory ul li {
  width: 97%;
  /* text-align: center; */
  font-size: 17px;
  /* padding-left: 80px; */
  text-align: justify;
}

ul {
  list-style: decimal;
  padding-left: 83px;
}

.gopaljilower h1 {
  text-align: center;
  color: #ffd301;
  font-weight: 700;
  padding: 10px;
}

.gopaljilower p {
  padding: 2px 5px 10px 5px;
  width: 97%;
  font-size: 17px;
  padding-left: 80px;
  text-align: justify;
}

.paraphilos p {
  padding: 2px 5px 10px 5px;
  width: 97%;
  font-size: 18px;
  padding-left: 80px;
  text-align: center;
}
.origin p {
  padding: 2px 5px 10px 5px;
  width: 97%;
  font-size: 17px;
  padding-left: 80px;
  text-align: justify;
}

.origin img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 2rem;
  margin-bottom: 3rem;
}

.explained p {
  padding: 2px 5px 10px 5px;
  width: 97%;
  font-size: 17px;
  padding-left: 80px;
  text-align: justify;
}

.explained ul li {
  width: 97%;
  /* text-align: center; */
  font-size: 17px;
  /* padding-left: 80px; */
  text-align: justify;
}

.founder p {
  padding: 2px 5px 10px 5px;
  width: 97%;
  font-size: 17px;
  padding-left: 80px;
  text-align: justify;
}

.founder ul li {
  width: 97%;
  /* text-align: center; */
  font-size: 17px;
  /* padding-left: 80px; */
  text-align: justify;
}

.secoffaq p {
  padding: 2px 5px 10px 5px;
  width: 97%;
  font-size: 17px;
  padding-left: 80px;
  text-align: justify;
}
.guideline img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 3rem;
  /* margin: 2rem 0rem 3rem 0rem;
   */
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 40rem;
}

.guidelin1 p {
  margin: 0;
  margin-bottom: -0.85rem;
}
.guidelin1 {
  padding: 10px 0px 10px 0px;
}

@media only screen and (max-width: 600px) {
  .aboutupper {
    flex-direction: column-reverse;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .parap {
    padding: 32px 13px 5px 13px;
  }

  .aboutupperright {
    flex-direction: column-reverse;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .aboutinner p {
    width: 100%;
  }
  .aboutupper img {
    width: 360px;
    height: 311px;
    margin-left: 10px;
    padding-top: 0px;
  }
  .aboutupperright img {
    width: 364px;
    height: 311px;
    margin-left: 10px;
    padding-top: 0px;
  }
  .aboutinner h2 {
    font-size: 37px;
    color: #ffd301;
    font-weight: 700;
    margin: 0px 0px 10px 0px;
    padding: 0px;
    text-align: center;
    width: 100%;
    /* text-align: justify; */
  }
  .gopaljilower p {
    padding: 2px 10px 10px 10px;
    width: 100%;
    font-size: 17px;
    /* padding-left: px; */
    text-align: justify;
  }
  .origin img {
    width: 21rem;
  }
  .sect h1 {
    font-size: 40px;
    font-weight: 500;
  }
  .paraphilos p {
    padding-left: 5px;
  }
  .origin p {
    padding-left: 10px;
  }
  ul {
    list-style: decimal;
    padding-left: 25px;
  }
  .founder p {
    padding: 2px 5px 10px 10px;
  }
  .explained p {
    padding: 2px 5px 10px 10px;
  }
  .guideline img {
    width: 20rem;
  }
  .secoffaq h1 {
    font-weight: 500;
    font-size: 36px;
  }
  .secoffaq p {
    padding: 2px 10px 10px 10px;
    width: 100%;
  }
  .gopaljiheading {
    background-repeat: no-repeat;
    /* padding: 275px 40px 0px 23px; */
    /* background-position: 30%; */
    /* background-size: 108% 69%; */
    /* background-size: cover; */
    /* margin: -73px 0px -124px -5px; */
  }
  .gopaljiheading h1 {
    text-align: center;
    /* color: white; */
    position: relative;
    /* top: -131px; */
    font-size: 28px;
    font-weight: 800;
  }
  .abouting{
    flex-direction: column !important;
  }
  .aboutustop{
    padding: 30px 0px 0px 0px;
  }
  .otherhistory p{
    padding-left: 10px;
  }
  .otherhistory ul{
    padding-left: 25px;
  }
}
