.countsection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeDescription{
    border-radius:20px;
    width: 90%;
    height: 120px;
    margin-top: 0px;
    background-image: url(./../images/download.webp);
    background-repeat: no-repeat;
    background-size: cover;
}

.countdiv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.countrow{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: -80px;
    height: 180px;
    background-image: url(../images/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    z-index: 1;
}

.countcolumn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 60px;
}

.countheading{
    color: #FF4A7A;
    font-weight: 700;
}

.countinfo{
    margin-left: 15px;
}

@media screen and (max-width:600px) {
    .countrow{
        flex-direction: column;
        width: 80%;
        height: 380px;
        padding: 20px 0;
    }

    .homeDescription{
        padding: 1rem 2rem;
        margin-top: 30px;
    }

    .countcolumn{
        margin: 0 30px;
    }
}