.dailyschedulediv{
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.dailyschedulerow{
    display: flex;
    width: 80%;
}

.dailyschedulecolumn{
    padding: 30px;
    flex: 1;
}

.dailyscheduletiming{
    display: flex;
    width: 100%;
}

.dailyscheduletime{
    width: 100%;
}

.dailyscheduledescription{
    width: 100%;
}

.dailyscheduleimage{
    width: 100%;
    height: 600px;
}

@media screen and (max-width:600px) {
    .dailyschedulerow{
        flex-direction: column;
        width: 100%;
    }

    .dailyschedulecolumn{
        width: 100%;
        padding: 10px;
    }
    
}