.differencediv{
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.differenceheading{
  margin-top: 40px;
  font-weight: 700;
}

.differencerow{
    display: flex;
    width: 90%;
    margin-top: 30px;
}

.differencecolumn{
    flex: 1;
    border-radius: 10px;
    text-align: center;
    margin: 20px;
    /* cursor: pointer; */
    padding: 30px 20px;
    transition: transform 0.3s ease;
}

.differencecolumn:hover{
    background-color: white;
    transform: scale(1.1); 
}

.differencepara{
    text-align: justify;
}

@media screen and (max-width: 600px) {
    .differencerow{
        flex-direction: column;
    }
}