.donateusdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.donateusrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
}

.donateuscolumn{
    flex: 1;
    text-align: center;
    border-radius: 10px;
    margin: 20px;
    transition: transform 0.3s ease-in-out;
    height: 42vh;
    width: 15%;
    padding: 0px 20px;
}

.donateuscolumninner{
box-shadow: 2px 2px 2px 2px #eee;
border-radius: 10px;
}

.donateuscolumnimage{
    height: 25vh;
}

.donateuscolumn:hover{
    transform: scale(1.1);
}

.donateusfirst{
    display: flex;
    width:95%;
    align-items: center !important;
    justify-content: space-between !important;
}

.donateusviewallbtn{
  border: none;
  border-radius: 15px;
  padding: 10px 15px; 
  background-color: #ffd301;
}

.donateusviewallbtn:hover{
    background-color: white;
    border: 2px solid #ffd301;
}

.donateusimage{
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 100%;
}

.donateuscolumnpara{
    text-align: center;
    margin: 20px 10px 10px 10px;
    height: 40px;
}
.donateuspara{
    font-weight: 600;
    text-align: center;
    width:100%; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.donateusbtn{
    border: none;
    padding: 10px 15px;
    border-radius: 15px;
    width: 95%;
    margin:0px 5px 20px 5px ;
    background-color: #ffd301;
}

.donateusbtn:hover{
    background-color: white;
    border: 2px solid #ffd301;
}

@media screen and (max-width:600px) {
    .donateusrow{
        flex-direction: column;
        width: 100%;
    }
    .donateuscolumn{
        width: 85%;
        margin: 20px 10px;
    }
    .donateusfirst{
        width: 95%;
    }
}