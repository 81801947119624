.newheaderdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffd301;
}

.newheaderlogo {
    width: 120px;
    height: 120px;
}

.loginimage {
    width: 50px;
    height: 50px;
}

.innerdivpara {
    margin-bottom: 5px;
    color: #231f40;
    font-weight: 600;
}


.innerdivpara:hover {
    color: rgb(255, 4, 4);
    font-weight: bold;
    transform: scale(1.1);
}

.newheaderrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 200px; */
}

.newheaderrow2 {
    position: sticky;
    top: 0;
    z-index: 99;
    height: 80px;
    /* justify-content: first baseline; */
}

.newheadercolumn1 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newheadercolumn2 {
    width: 80%;
}

.newheaderinnerdiv {
    display: flex;
    padding-top: 20px;
}

.newheaderinnerdiv1 {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: center;
}

.newheaderinnerdivinnerdiv1 {
    width: 10%;
}

.newheaderinnerdivinnerdiv2 {
    width: 85%;
    margin-left: 20px;
}

.newheaderinnerdiv2 {
    display: flex;
    width: 95%;
    padding-top: 20px;
    /* padding-right:20px ; */
}

.newheaderheading {
    font-weight: 800;
    color: white;
    /* font-family: "Pacifico"; */
}

.newheaderspan {
    color: white;
    padding: 0px 10px;
    font-size: 30px;
    padding-left: 50px;
    background-image: url(../images/status-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.headerlistdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 40px 40px 0px 0px;
    width: 88%;
    margin: 30px 0px;
}

.newheaderul {
    list-style: none;
    display: flex;
    padding: 0;
    width: 100%;
}

.newheaderli {
    padding: 15px 30px 0px 30px;
    font-size: 25px;
    font-weight: 800;
}

.newheaderbtn {
    background-color: #ffd301;
    padding: 0px 10px;
    border: 2px solid #ffd301;
    border-radius: 15px;
    margin-bottom: 20px;
    /* height: 60px; */
}

.buttondiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-menu-btn {
    display: none;
    cursor: pointer;
}

.newheaderlogodiv {
    display: none;
}

.newheaderdiv1 {
    display: none;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    background-color: #ffd301;
    padding: 15px 0px;
}

.newheaderlogo1 {
    width: 70px;
    height: 70px;
}

.newheaderdiv1column {
    border-radius: 40px 0px 0px 0px;
    background-color: white;
    padding-left: 20px;
}

.newheaderdiv1column1 {
    border-radius: 0px 40px 0px 0px;
    padding: 5px 0px;
    background-color: white;
}

.livedarshanlink1 {
    display: none;
}

.callusdiv {
    display: block;
    position: fixed;
    top: 17%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 60%;
    border-radius: 10px;
}

.callusdivpara {
    margin-bottom: 5px;
    color: black;
    font-weight: 600;
}

.emaildivpara {
    margin-bottom: 5px;
    color: black;
    font-weight: 600;
}

.emaildiv {
    display: block;
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 60%;
    border-radius: 10px;
}

@keyframes blinker {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.blinkme {
    height: 80px;
    width: 80px;
    animation: blinker 3s linear infinite;
    color: rgb(255, 1, 1);
}

.modelcloseButton1 {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    color: black;
    font-size: 20px;
    cursor: pointer;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
}

.mobilecopynumber {
    background-color: #eee;
    border: none;
    padding: 5px 8px;
    border-radius: 10px;
}

.emailcopynumber {
    background-color: #eee;
    border: none;
    padding: 5px 8px;
    border-radius: 10px;
}

/* Add this to your stylesheet */
@keyframes marqueeAnimation {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.marquee-container {
    overflow: hidden;
    white-space: nowrap;
}

.marquee-content {
    display: inline-block;
    color: #231f40;
    animation: marqueeAnimation 60s linear infinite;
}


@media screen and (max-width:600px) {
    .newheaderdiv {
        position: sticky;
        top: 0;
        z-index: 99;
    }

    .newheaderrow {
        flex-direction: column-reverse;
    }

    .newheadercolumn1 {
        display: none;
    }

    .newheaderlogodiv {
        display: block;
        padding: 0px 0px 0px 30px;
    }

    .newheaderrow2 {
        display: flex;
        flex-direction: row;
        /* display: none; */
    }

    .newheaderinnerdivinnerdiv2 {
        display: none;
    }

    .newheaderinnerdiv2 {
        display: none;
    }

    .newheaderinnerdiv {
        width: 100%;
        padding-top: 0px;
        border-bottom: 1px solid black;
    }

    .newheaderlogo {
        width: 80px;
        height: 80px;
    }

    .loginimage {
        height: 45px;
        width: 45px;
    }

    .newheadercolumn2 {
        width: 100%;
    }

    .newheaderinnerdivinnerdiv1 {
        width: 20%;
        padding: 20px 0px;
    }

    .newheaderinnerdiv1 {
        height: 50px;
        border-right: 1px solid black;
    }

    .headerlistdiv {
        margin: 10px 10px;
        padding: 10px 0px;
        border-radius: 0px;
        background-color: #ffd301;
    }

    .newheaderli {
        display: none;
    }

    .mobile-menu-btn {
        display: block;
    }

    .newheaderul {
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background-color: #333;
        z-index: 1;
        display: none;
    }

    .newheaderul.show {
        display: flex;
    }

    .newheaderul li {
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #555;
    }

    .newheaderbtn {
        color: white;
        background-color: black;
    }

    .buttondiv {
        justify-content: start;
    }

    .livedarshanlink1 {
        display: block;
    }

    .livedarshanlink {
        display: none;
    }
}