
.videogallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url(../images/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
  }
  
  .videogalleryheading{
    font-weight: 700;
    margin-top: 30px;
  }
  
  .row1 {
    display: flex;
    width: 100%;
  }
  
  .videogalleryvideodiv {
    width: 30%;
    margin: 20px;
  }
  
  .videogalleryvideo {
    width: 100%;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
  }
  
  .model{
     width: 100%;
     height: 100vh;
     position: fixed;
     top: 0;
     left: 0;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: #000000;
     transition:opacity .4s ease,visibility .4s ease, transform .5s ease-in-out;
     visibility: hidden;
     opacity: 0;
     transform: scale(0);
     overflow: hidden;
     z-index: 999;
  }
  
  .model.open{
     visibility: visible;
     opacity: 1;
     transform: scale(1);
  }
  
  .modelimage{
     width: auto;
     max-width: 100%;
     height: auto;
     max-height: 100%;
     display: block;
     line-height: 0;
     box-sizing: border-box;
     padding: 0 20px 20px;
     margin: 0 auto;
  
  }
  
  .modelcloseButton{
     position: fixed;
     top: 10px;
     right: 10px;
     width: 2rem;
     height: 2rem;
     padding: 5px;
     background-color: rgba(0, 0, 0, 0.4);
     color: #ffffff;
     cursor: pointer;
  }
  
  
  
  @media screen and (max-width:600px) {
    .row1{
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
     .videogalleryvideodiv{
        margin: 10px;
        width: 85%;
     }
  }