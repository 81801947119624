.sliderStyles{
    height: 80vh;
    overflow-y: hidden;
}

.slidestyle{
    width:100%;
    height:100%
}
.imgStyles{
    width: 100%;
    height: 100%;
    /* border-radius: 20px; */
}

.rightArrowStyles{
    position: absolute;
    top: 65%;
    margin-top:50px;
    transform: translate(0, -50%);
    left: 100px;
    font-size: 40px;
    color: #e6b745;
    z-index: 1;
    cursor: pointer;
    background-color: #eee;
    border-radius: 50%;
    padding: 0px 20px;
}

.leftArrowStyles{
    position: absolute;
    top: 65%;
    margin-top:50px;
    transform: translate(0, -50%);
    right: 100px;
    font-size: 40px;
    color: #e6b745;
    z-index: 1;
    cursor: pointer;
    background-color: #eee;
    border-radius: 50%;
    padding: 0px 20px;
}

.slidesContainerOverflowStyles{
    overflow: hidden;
    height: 100%,
}

@media screen and (max-width: 600px) {
    .slidecontainer{
        height: 50vh !important;

    }
    .imgStyles{
        width: 100%;
        height: 50vh;
    }

    .slidestyle{
        height: 50vh !important;

    }
    .sliderStyles{
        height: 50vh !important;
    }

    .rightArrowStyles{
        margin-top: 0px;
        /* margin-bottom: 60px; */
        top: 50%;
        left: 30px;
        font-size: 20px;
        padding: 10px 15px;
    }

    .leftArrowStyles{
        margin-top: 0px;
        /* margin-bottom: 60px; */
        top: 50%;
        right: 30px;
        font-size: 20px;
        padding: 10px 15px;
    }
}
