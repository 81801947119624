.footerupper {
  /* background-color: black; */
  background-image: url(./../images/26539.jpg);
  background-repeat: no-repeat;
  background-size: 100% 130%;
  /* background-size: cover; */

  margin:0;
  padding: 0.5rem 0rem 2rem 0rem;
}
.lowerring{
  background-color: transparent !important;
}
.footerlower{
  background-color: #ffd301;
  padding: 10px 2px 10px 2px;
}
.footerIcon{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-right: 60px;
}
.footericonul {
  list-style-type: none;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin: 0rem 0rem 4rem 0rem;
}
.footerIcon ul li {
  text-decoration: none;
  margin: 0.5rem;
}
.footerlower {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.footerlower a {
  color: black;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}
.footerIcon ul li a img {
  width: 45px;
}

@media only screen and (max-width: 600px) {
  .footerIcon{
    padding-right: 0px;
  }
  .footerIcon ul li a img {
    width: 45px;
  }
  .footerlower {
    flex-direction: column;
    padding-left: 1rem;
    align-items: center; 
    /* justify-content: center; */ 
    padding-right: 1rem;
  }
  .footerIcon ul {
    padding-left: 0;
  }
  .footerlower a {
    text-align: center;
}
}
