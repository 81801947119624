.latestblogdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
}

.latestblogrow {
    display: flex;
    width: 90%;
}

.latestblogheading {
    font-weight: 600;
}

.latestblogcolumn {
    height: 80vh;
    display: flex;
    flex-direction: column;
    width: 25%;
    margin: 10px 20px;
    padding: 0 20px;
    
}

.latestblogcolumninner{
    box-shadow: 2px 2px 2px 2px #eee;
    border-radius: 10px;
}

.latestblogheading {
    margin-left: 25px;
}

.latestbloginnerheadingdiv{
 height: 80px;
}

.latestbloginnerheading {
    padding-left: 10px;
    margin-top: 20px;
    width:100%; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.lagestblogimagediv{
    height: 30vh;
}

.latestblogimage {
    /* border-radius: 40px 10px 80px 10px; */
    width: 100%;
    height: 100%;
}

.latestblogpara {
    padding: 0px 10px;
    width:100%; 
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.latestblogbtn {
    width: 95;
    border: none;
    border-radius: 15px;
    padding: 15px 10px;
    background-color: #ffd301;
    margin: 10px 20px 20px 20px;
}

.latestblogbtn:hover{
    background-color: white;
    border: 2px solid #ffd301;

}

@media screen and (max-width:600px) {
    .latestblogrow{
        flex-direction: column;
    }
    .latestblogcolumn{
        width: 95%;
        margin: 10px;
        padding: 0 10px;
    }
}