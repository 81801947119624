.templeplandiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.templeplanheading{
    text-align: center;
    font-weight: 700;
    margin: 30px 0px;
}

.templeplanimage{
    width: 90%;
    padding: 30px 0px;
}