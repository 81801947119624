.founderdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.founderheading{
    font-weight: 600;
}

.founderrow{
    display: flex;
    width: 70%;
    margin: 30px 0px 80px 0px;
}

.foundercolumn{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
}

.foundercolumninnerdiv{
    padding-left: 30px;
}

.founderimage{
    width: 450px;
    height: 280px;
    box-shadow: 5px 5px 5px 5px gray;
}

.founderbtn{
    padding: 10px 15px;
    border: none;
    border-radius: 15px;
    border: 2px solid #ffd301;
    font-weight: 500;
}

.founderbtn:hover{
background-color: #ffd301;
}

@media screen and (max-width:600px) {
    .founderrow{
        flex-direction: column;
        width: 95%;
    }
    .foundercolumn{
        width: 95%;
    }

    .founderimage{
        width: 90%;
        height: 200px;
        /* padding: 0px 15px 15px 15px; */
    }
    .foundercolumninnerdiv{
        padding: 15px;
    }
}