.getinvolvediv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

.getinvolveheading {
    font-weight: 600;
}

.getinvolverow {
    display: flex;
    width: 90%;
}

.getinvolvecolumn {
    flex: 1;
    margin: 10px 20px;
    cursor: pointer;
  
    height: 60vh;
    padding: 0 20px ;
}

.getinvolvecolumninner{
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px #eee;
}

.getinvolvecolumn:hover {
    transform: translateY(-20px);
}

.getinvolveimgdiv{
    height: 45vh;
}
.getinvolveimg {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0px 0px;
}

.getinvolvebtndiv {
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px 0px 10px 10px;
}

.getinvolvebtn {
    padding: 15px 10px;
    border: none;
    border-radius: 15px;
    border: 2px solid #ffd301;
}

.getinvolvebtn:hover{
    background-color: #ffd301;
}

@media screen and (max-width:600px) {
    .getinvolverow{
        flex-direction: column;
    }
}