
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

nav {
  position: sticky;
  z-index: 99;
  width: 100%;
  /* background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: cover; */
  top: 0;
}
nav .wrapper {
  /* background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: cover; */
  position: relative;
  max-width: 1300px;
  padding: 0px 30px;
  height: 60px;
  line-height: 40px;
  margin: auto;
  display: flex;
  color: black;
  align-items: baseline;
  justify-content: space-between;
  /* border-radius: 74px; */
}
.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .nav-links {
  width: 100%;
  display: inline-flex;
}
.nav-links li {
  list-style: none;
}
.nav-links li a {
  color: #231f40;
  text-decoration: none;
  font-size: 23px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.nav-links li a img{
  margin-right: 5px;
}
.nav-links li a:hover,
.nav-links li a.active {
  color: rgb(141, 9, 9);
}

.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background-image: url(./../images/download.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 230px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
  padding-left: 3px;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box {
  position: absolute;
  left: 5%;
  width: 100%;
  /* padding: 0 30px; */
  top: 85px;
  opacity: 0;
  visibility: hidden;
  display: block;
  height: auto;
}
.mega-box .content {
  background-image: url(./../images/background-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  /* height: 70vh; */
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.rowww {
  /* width: calc(48% - 0px); */
  line-height: 28px;
  width: 100%;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  padding-left: 10px;
}

.rowww0{
  width: 30%;
}
.rowww1{

  display: flex;
}

.rowww1column{
  /* padding-left: 20px; */
  width: 100%;
}
.content .rowww img {
  /* width: 92%;
  height: 79%; */
  /* object-fit: cover; */
  border-radius: 60px;
  padding: 15px 0px 15px 25px;
  width: 100%;
  height: 100%;
}
.content .rowww header {
  color: maroon;
  font-size: 20px;
  font-weight: 500;
  margin-left: 14px;
}
.content .rowww .mega-links {
  padding-left: 0;
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}
.rowww .mega-links li {
  padding: 0px;
}
.rowww .mega-links li a {
  color: #000000;
  font-size: 17px;
  display: block;
  width: 121%;
  margin-bottom: -15px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.rowww .mega-links li a:hover,
.rowww .mega-links li a.active{
  color: maroon;
}
.rowww img{
  width: 20rem;
}
.bansuri{
  color: white;
  width: 70px !important;
}
.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 600px)  {  
  nav .wrapper {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .wrapper .btn {
    display: block;
  }
  .rowww1{
    flex-direction: column;
    width: 100%;
  }
  .logo a img {
    width: 85px;
  }
  .rowww img{
    width: 13rem;
  }
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background-image: url(./../images/download.webp);
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 26px;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showDrop1:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box,
  #showMega1:checked ~ .mega-box,
  #showMega2:checked ~ .mega-box,
  #showMega3:checked ~ .mega-box,
  #showMega4:checked ~ .mega-box,
  #showMega5:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item {
    color: black;
    display: none;
  }
  .nav-links .mobile-item {
    display: block;
    color: black;
    font-size: 26px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    background: maroon;
    width: 100%;
  }
  .drop-menu li {
    margin: 0;
  }
  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    width: 100%;
    visibility: visible;
    padding: 0 5px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    width: 100%;
    /* padding: 20px 20px 0 20px; */
  }
  .mega-box .content .rowww {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
  .mega-box .content .rowww:nth-child(1),
  .mega-box .content .rowww:nth-child(2) {
    border-top: 0px;
  }
  .content .rowww .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .rowww .mega-links li {
    margin: 0;
  }
  .content .rowwww header {
    font-size: 19px;
  }
  .rowww .mega-links li a {
    padding: 0px;
    padding: 0px;
    color: #000000;
    font-size: 13px;
    display: block;
    /* width: 100%; */
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .headermenu {
    width: fit-content;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}
