.getintouchdiv{
    background-image: url(../images//Lord.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.getintouchrow{
    display: flex;
    width: 80%;
}

.getintouchcolumn1{
    background-color: white;
    padding: 40px;
}

.getintouchinputdiv{
    display: flex;
}

.getintouchniputdiv1{
    display: flex;
    flex-direction: column;
    margin: 0px 15px 15px 0px;
}

.getintouchinput{
    background: #f7f7f7;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    box-shadow: none;
    color: #787878;
    transition: all .5s;
    padding: 12px;
}

.getintouchbtn{
    padding: 12px;
    border: none;
    background-color: #ffd301;
    font-size: 20px;
    font-weight: 600;
}

.getintouchbtn:hover{
    background-color: white;
    border: 2px solid #ffd301;

}

@media screen and (max-width:600px) {
    .getintouchrow{
        flex-direction: column;
        width: 100%;
    }

    .getintouchcolumn1{
        padding: 10px;
        width: 100%;
    }

    .getintouchinput{
        width: 100%;
    } 
}