.upcomingeventdiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.upcomingeventsrow{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upcomingeventscolumn{
    /* flex: 1; */
    width: 50%;
    padding: 10px 30px;
}

.upcomingeventscolumn1{
    border-right: 5px solid black;
}

.upcomingeventsheading{
    text-align: center;
    font-weight: 800;
}

.upcomingeventsheading1{
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
}

.upcomingeventscolumninnerdiv{
    display: flex;
    margin: 0px 15px 15px 15px;
}

.upcomingeventscolumninnerdivdate{
    float: left;
    margin-right: 20px;
    color: black;
    background-color:#ffd301;
    padding: 10px 10px 0 10px;
    border-radius: 10px;
    width: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upcomingeventscolumninnerdivinfo{
    justify-content: last baseline;
}

.datediv{
    font-weight: 700;
}
.upcomingeventscolumninnerdivinfo{
    margin-left: 20px;
}

.upcomingeventsbuttondiv{
    display: grid;
    place-content: center;
    margin-top: 30px;
}

.upcomingeventsbtn{
    padding: 15px;
    border-radius: 15px;
    border: 2px solid #ffd301;
}

.upcomingeventsbtn:hover{
    background-color: #ffd301;
}


.upcomingeventslistdiv{
    overflow: auto;
    /* height: 80%; */
    height: 500px;
    margin-bottom: 20p;
}

@media screen and (max-width:600px) {
    .upcomingeventsrow{
        flex-direction: column;
        width: 95%;
    }

    .upcomingeventscolumn{
        width: 95%;
        padding: 0px;
    }
    .upcomingeventscolumninnerdiv{
        margin: 0px 0px 20px 0px;
    }
    .upcomingeventscolumn1{
        border-right: none;
    }

    .upcomingeventsheading{
        margin-bottom: 30px;
    }
    .upcomingeventsheading1{
        margin-top: 30px;
    }
}