.cardsliderdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.slider-card {
  text-align: center;
}

.cardsliderfirst{
  width: 90%;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 20px;
}

.cardsliderfirst1{
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardsliderfirstheading{
  font-weight: 600;
}

.cardsliderfirstbutton{
  border: none;
  border-radius: 15px;
  padding: 10px 15px; 
  background-color: #ffd301;
}

.cardsliderfirstbutton:hover{
  background-color: white;
  border: 2px solid #ffd301;
}

.explorepurislider{
  width: 95%;
  /* height: 50vh; */
}

.explorepuricard{
  text-align: center;
  background-color: white;
  padding:0px 10px;
  height: 35vh;
  margin: 20px 0;
}

.explorepuriimage{
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
}

.explorepuriinnerdiv2{
  padding: 10px 0px 5px 0px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 3px 3px 3px 3px #eee;
}

@media screen and (max-width:600px) {
  .explorepuricard{
    height: 45vh;
  }
}