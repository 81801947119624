.checkoutnextdiv{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    /* height: 60vh; */
}

.checkoutnextrow{
    width: 50%;
    text-align: center;
    border: 1px solid #ccc;
    padding: 30px 0;
    border-radius: 10px;
    background-color: #eee;
}

.checkoutnextbtn{
    margin: 30px;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    background-color: #ffd301;
    font-size: 600;
}

.gobackbtndiv{
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width:600px) {
    .checkoutnextrow{
        width: 90%;
    }
}