.connectusonlineHeading {
    padding-top: 20px;
    text-align: center;
  }

  .connectusonlineHeading h1{
    font-weight: 700;
  }

  .connectusonlinerow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-bottom: 20px; */
  }
  
  .connectusonlinecolumn {
    flex: 1;
    margin: 15px;
    border:3px solid brown;
    border-radius: 8px;
    background-image: url(./../images/download.webp);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .cardStyles{ 
    padding: 20px;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
  }

  .buttonContainerStyles{
    display: flex;
    flex-direction:column;
    gap: 10px
  }

  .buttonStyles{
    padding: 10px 20px;
    background-color: orange;
    color: white;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease;
    text-align: center;
  }

  .buttonStyles:hover{
    transform: scale(1.1);
    color: black;
  }

  .cardStyles h3{
    text-align: center;
    font-weight: 600;
  }
  

  @media screen and (max-width: 600px) {
    .connectusonlineSection{
      padding: 0px;
    }
  
    .connectusonlinerow{
      padding: 0px;
      flex-direction: column;
    }
  }