.contentdiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
}

.contentrow{
    display: flex;
    width: 95%;
}

.contentcolumn{
    flex: 1;
    text-align: center;
    width: 15%;
    height: 30vh;
    margin: 10px;
    padding: 0px 20px;
    font-style: oblique;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.contentcolumn:hover{
    transform: scale(1.2);
}

.contentimagediv{
    height: 25vh;
}
.contentimage{
    width: 100%;
    height: 100%;
}
.contentheadingdiv{
    padding-top: 20px;
}

.contentheading{
    font-weight: 600;
    text-align: center;
    width:100%; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.slick-slide {
    column-gap: 30px;
}

@media screen and (max-width:600px) {
    .contentrow{
        flex-direction: column;
        width: 95%;
    }

    .contentcolumn{
        width: 95%;
    }

    .contentimage{
        width: 100%;
    }
}