.timingdiv{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    /* background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover; */
    display: block;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 60%;
    border-radius: 10px;
}

.timingrow{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.timingcolumn{
    flex: 1;
    width: fit-content;
}

.timingcolumndiv{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.timingcolumndivimg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 50px;
    border-radius: 50%;
    background: #d0f0ff;
}
.timingcolumndivtime{
    width: 30%;
    text-align: end;
    padding-right:20px;
}

.timingcolumndivdescription{
    width: 90%;
    text-align: start;
    padding-left:20px;
}

.closeButton1 {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 40px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.closeButton1:hover {
    color: #ff0000; 
  }
  
@media screen and (max-width: 600px) {
    .timingdiv{
        /* left: 40%; */
        right: 20%;
        top:50%;
        width: 85%;
        height: 90vh;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 20px 20px 20px 10px;
    }
    .timingrow{
        width: 100%;
        flex-direction: column;
    }
    .timingcolumndivdescription{
        width: 100%;
        padding-left: 0px;
        padding-top: 10px;
    }
    .timingcolumndivtime{
        padding-top: 10px;
    }
    .timingcolumn{
        width: 100%;
    }
    .closeButton1{
        right: -5px;
        top: 5px;
    }
    .timingcolumndivimg{
        margin-right: 10px;
    }

}
