.footer1div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.footer1row{
    display: flex;
    width: 90%;
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px 10px 10px 0px;
}
.footer1column{
    flex: 1;
    width: 30%;
    padding: 20px;
}
.footer1columnimg{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer1logo{
    width: 150px;
    height: 150px;
    transition: transform 0.3s ease-in-out;
}

.footer1logo:hover{
    transform: scale(1.1);
}

.footer1btn{
    width: 70%;
    padding: 10px;
    background-color: #ffd301;
    border-radius: 0px 20px 20px 20px;
    border: none;
    transition: transform 0.3s ease-in-out;
}

.footer1btn:hover{
    transform: scale(1.1);
}
.footer1row1{
    background-color: #ffd301;
    display: flex;
    width: 90%;
    justify-content: space-between;
    padding: 12px;
    border-radius: 0px 20px 20px 20px;
    margin-bottom: 10px;
}
.footer1link{
    text-decoration: none;
    color:black;
    font-weight: 600;
}

.homelink{
    text-decoration: none;
    color: black;
    transition: transform 0.3s ease;
}

.homelink:hover {
    transform: translateX(10px);
}

.footericonimage{
    height: 200px;
    width: 200px;
}
@media screen and (max-width:600px) {
    .footer1row{
        flex-direction: column;
        width: 100%;
    }
    .footer1column{
        width: 100%;
    }
    .footer1row1{
        width: 95%;
        padding: 5px;
        flex-direction: column;
    }
}