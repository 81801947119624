.herosection{
    height: 73vh;
    width: 100%;
    background-image: url('../images/homeherosection\ \(2\).jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position:100% center ;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
}

.herosection::after{
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.6;
  z-index: -1;
}

.herosectiondot div {
    height: 60px;
    width: 60px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 50px;
    position: absolute;
    top: 10%;
    left: 10%;
    animation: 4s linear infinite;
    z-index: 1;
  }
  div .dot {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 20%;
    right: 20%;
    z-index: 1;
  }
  .herosectiondot div:nth-child(1) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .herosectiondot div:nth-child(2) {
    top: 60%;
    left: 80%;
    animation: animate 10s linear infinite;
  }
  .herosectiondot div:nth-child(3) {
    top: 40%;
    left: 40%;
    animation: animate 3s linear infinite;
  }
  .herosectiondot div:nth-child(4) {
    top: 66%;
    left: 30%;
    animation: animate 7s linear infinite;
  }
  .herosectiondot div:nth-child(5) {
    top: 90%;
    left: 10%;
    animation: animate 9s linear infinite;
  }
  .herosectiondot div:nth-child(6) {
    top: 30%;
    left: 60%;
    animation: animate 5s linear infinite;
  }
  .herosectiondot div:nth-child(7) {
    top: 70%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .herosectiondot div:nth-child(8) {
    top: 75%;
    left: 60%;
    animation: animate 10s linear infinite;
  }
  .herosectiondot div:nth-child(9) {
    top: 50%;
    left: 50%;
    animation: animate 6s linear infinite;
  }
  .herosectiondot div:nth-child(10) {
    top: 45%;
    left: 20%;
    animation: animate 10s linear infinite;
  }
  .herosectiondot div:nth-child(11) {
    top: 10%;
    left: 90%;
    animation: animate 9s linear infinite;
  }
  .herosectiondot div:nth-child(12) {
    top: 20%;
    left: 70%;
    animation: animate 7s linear infinite;
  }
  .herosectiondot div:nth-child(13) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .herosectiondot div:nth-child(14) {
    top: 60%;
    left: 5%;
    animation: animate 6s linear infinite;
  }
  .herosectiondot div:nth-child(15) {
    top: 90%;
    left: 80%;
    animation: animate 9s linear infinite;
  }
  @keyframes animate {
    0% {
      transform: scale(0) translateY(0) rotate(70deg);
    }
    100% {
      transform: scale(1.1) translateY(-100px) rotate(360deg);
    }
  }

  .herosectionheadingdiv{
    height: 100px;
  }

  .herosectionrow{
    display: flex;
    width: 85%;
    z-index: 2;
  }

  .herosectioncolumn{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .herosectionheading{
    font-size: 3rem;
    color: white;
    margin-bottom: 30px;
  }

  .herosectionimg{
    width: 400px;
    height: 400px;
    border-radius: 5px;
    /* z-index: 2; */
  }

  .herosectionbtn{
    padding: 10px;
    margin: 15px 15px 15px 0;
    border-radius: 10px;
    background-color: #ff7c08;
    color: white;
    border: none;
  }


  @media screen and (max-width:600px) {
    .herosection{
        height: 85vh;
    }

    .herosectionheadingdiv{
        height: 50px;
    }

    .herosectionheading{
        margin-bottom: 0;
        font-size: 2rem;
    }

    .herosectionrow{
        flex-direction: column;
        gap: 20px;
    }

    .herosectioncolumn{
        width: 100%;
    }

    .herosectionimg{
      height: 200px;
      width: 100%;
    }
  }