.servicesdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.servicesfirst{
    display: flex;
    width:90%;
    align-items: center !important;
    justify-content: space-between !important;
}

.servicesslider{
    width: 96%;
}

.servicescard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    height: 300px;
    width: 15%;
}

.servicesinnerdiv1{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: beige;
    padding: 30px 0px; 
}
.serviceinnerdiv2{
    padding: 10px 0px; 
}

.servicesimage{
    border-radius: 10px 10px 0px 0px;
    height: 100px;
    width: 100px;
}

.servicepara{
    text-align: center;
    font-size:2px;
    width:100%; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.servicebutton{
        width: 100%;
        background-Color: #ffd301;
        border:none;
        border-Radius:10px;
        padding:10px
}

.servicebutton:hover{
    background-color: white;
    border: 2px solid #ffd301;

}