
.videogallerydiv {
    height: 65vh;
    background-image: url(./../images/download.webp);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .videogalleryheading {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  
  .videogallerysliderdiv {
    width: 80%;
    margin: 0 auto;
  }
  
  .videoclass {
    height: 300px;
    margin: 0 10px;
  }
  
  .videoclass video {
    width: 94% !important;
    height: 300px;
    padding: 0px 20px; 
  }
  
  @media screen and (max-width:600px){
    .videogallerydiv{
      height: 45vh;
    }
    .videogallerysliderdiv{
      width: 95%;
    }
    .videogalleryheading{
      font-size: 30px;
      margin-bottom: 10px;
    }
    .videoclass{
      height: 150px;
    }
  
    .videoclass video{
      height: 150px;
      padding: 0px 10px;
    } 
  }
  
  