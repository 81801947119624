.donationsdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url(https://www.iskconpune.com/wp-content/uploads/2023/01/background-1.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.donationsheading{
    margin-bottom: 30px;
}

.donationsrow{
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.donationscolumn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.donationsimage{
    width: 100%;
}

@media screen and (max-width:600px) {
    .donationsrow{
        flex-direction: column;
        width: 100%;
    }

    .donationsimage{
        width: 80%;
        height: 200px;
    }
}