.darshnupdatediv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

.darshanupdateheading{
    font-family: 'Tangerine', sans-serif; 
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
}

.darshanupdaterow {
    width: 80%;
    justify-content: space-between;
}

.darshanupdatecard {
    padding: 0px 50px;
    width: 70%;
    background-color: white !important;
}

.darshanupdateimage{
    width: 100%;
    height: 400px;
}

.slick-dots{
    bottom:-20px;
}

@media screen and (max-width:600px) {
    .darshanupdatecard{
        padding: 0 10px;
    }

    .darshanupdateimage{
        height: 300px;
    }
}